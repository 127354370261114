import styled from "styled-components"

export const PropertyContainer = styled.div`
  .text-centered {
    margin: 0 auto;
  }
  padding: 30px 0 !important;

  .col-lg-5 {
    padding-left: 0;
  }

  .hallway-img {
    border-radius: 0 14px 14px 0;
    &:hover {
      img {
        transform: scale(1.1);
        transition: 0.1s ease-in;
      }
    }

    @media (max-width: 992px) {
      width: 90%!important;
      margin:0 auto;
      margin-bottom:20px;
      border-radius: 14px;
      display:flex!important;
    }
  }
`

export const TextContainer = styled.div`
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 100%;

  h2 {
    color: var(--darkText);
    font-weight: bold;
  }

  p.minimized {
    width: 70%;
    color: var(--lightText);

    @media (max-width: 992px) {
      width: 100%;
    }
  }
  p.w-0 {
    width: unset;
    color: var(--lightText);

    @media (max-width: 992px) {
      width: 100%;
    }
  }
`

export const Types = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 30px;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 20px;
  align-items: flex-end;

  a {
    text-decoration: none;
  }

  @media (max-width: 576px) {
    flex-direction: column;
    align-items: center;
  }
`

export const TypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  gap: 20px;
  border-radius: 10px;
  color: var(--lightGreen);
  text-decoration: none;

  h6 {
    text-decoration: none;
  }

  &:hover {
    box-shadow: 0 0 10px rgb(0 0 0 / 17%);
  }
`

export const ViewAll = styled.div`
  width: 100%;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  padding: 30px 0;
  font-weight: bold;

  span {
    line-height: 1;
  }

  a {
    color: var(--lightGreen);
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0;
    margin: 0;

    &:hover {
      gap: 20px;
      transition: 0.2s ease-in;
      color: var(--lightGreen);
    }
  }
`
