import React, { useEffect, useState } from "react"
import { EventContent, EventsContainer, UpperText } from "./LatestEvents.styles"

import EventItem from "../GlobalComponents/EventItem"
import ExploreMore from "../GlobalComponents/ExploreMore"
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore"
import { db } from "../../firebase/config"

import useWidget from "../../hooks/useWidget";

import Loader from "../../components/Loader";

const LatestEvents = (props) => {
  const widgetData =useWidget('our-latest-events')[0];
    const arabic = props.arabic;
  const [events, setEvents] = useState([])

  const [loading,setLoading] = useState(true);
  useEffect(() => {
    setEvents(props.events.edges)
  }, [])
  useEffect(() => {
    setLoading(false);
  },[widgetData])
  return (
    <EventsContainer>
      <div className="container">
        <UpperText className={arabic==false ? '':'flex-row-reverse w-0'}>
          <div className={arabic==false ? "text":"text text-end"}>
            <h2>{widgetData && arabic==false ? widgetData.title:widgetData.title_ar}</h2>
            <p className={arabic== false ? '':'w-100'}>
            {widgetData && arabic == false ? widgetData.description : widgetData.description_ar}
            </p>
          </div>
          <ExploreMore text={widgetData && arabic==false? widgetData.link_text:widgetData.link_text_ar} link={widgetData && widgetData.link} />
        </UpperText>
        <EventContent>
          {props.events.edges.map(event => {
            return <EventItem page='index' key={event.id} event={event} arabic={arabic} />
          })}
        </EventContent>
      </div>
    </EventsContainer>
  )
}

export default LatestEvents
