import styled from "styled-components";

export const Container = styled.div`
* {
    box-sizing: border-box;
  }
  margin:0 auto;
  margin-top:20px;
  margin-bottom:20px;
  justify-content:center;
  text-align:center;
  max-width:1700px;

  .darkblue {
    background-color:var(--darkBlue)!important;
  }

  .darkgreen {
    background-color:var(--lightGreen)!important;
  }

.pricing-tables {
  display: flex;
    flex-wrap: wrap;
    justify-content:center;
}
  .columns {
    flex: 1 0 25%; /* Each image takes up 25% of the width */
    max-width: 25%; /* Prevents images from stretching beyond 25% width */
    margin: 5px; /* Optional for spacing */
  }
  
  .price {
    list-style-type: none;
    border: 1px solid #eee;
    margin: 0;
    padding: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  
  .price:hover {
    box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2)
  }
  
  .price .header {
    background-color: var(--lightText);
    color: white;
    font-size: 25px;
  }
  
  .price li {
    border-bottom: 1px solid #eee;
    padding: 20px;
    text-align: center;
  }
  
  .price .grey {
    background-color: #eee;
    font-size: 20px;
  }
  
  .button {
    background-color: #04AA6D;
    border: none;
    color: white;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
  }
  
  @media only screen and (max-width: 800px) {
    .columns {
        flex: 1 0 100%; /* Each image takes up 25% of the width */
        max-width: 75%; /* Prevents images from stretching beyond 25% width */
        margin-bottom: 10px; /* Optional for spacing */
      }
`;