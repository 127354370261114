import React from "react"
import {
  ArticleContainer,
  ImageContainer,
  DateContainer,
} from "./GlobalComponents.styles"
import Img from "gatsby-image"

import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const NewsItemBlog = ({ id, record, type, arabic }) => {
  const ImageStyle = { background: "none", width: "auto", height: "auto" }
  return (
    <div className="col-md-4">
      <ArticleContainer>
        <ImageContainer style={ImageStyle}>
          {/* <StaticImage
            src='https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/widgets%2Fnews-img.png?alt=media&token=76aaf86c-f844-4662-95a2-6e9d15c8f551'
            alt="news image"
            placeholder="tracedSVG"
            layout="constrained"
            className="news-img"
          />  */}
          {type === "qr" && (
            <StaticImage
              src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/widgets%2Fnews-img.png?alt=media&token=76aaf86c-f844-4662-95a2-6e9d15c8f551"
              alt="news image"
              placeholder="tracedSVG"
              layout="constrained"
              className="news-img"
            />
          )}
          {type === "local" && <img src={record.featured_image} alt="local" />}
        </ImageContainer>
        <DateContainer>
          <div className="author">
            <StaticImage
              src="../../assets/icons/author.svg"
              alt="author icon"
            />
            <p>{record.author.displayName}</p>
          </div>
          <div className="date">
            <StaticImage
              src="../../assets/icons/calendar.svg"
              alt="calendar icon"
            />
            <p>
              {type === "qr" && new Date(record.published).getDate()}{" "}
              {type === "local" &&
                new Date(record.created_at.seconds * 1000).getDate()}{" "}
              {type === "qr" &&
                new Date(record.published).toLocaleString("en-US", {
                  month: "short",
                })}{" "}
              {type === "local" &&
                new Date(record.created_at * 1000).toLocaleString("en-US", {
                  month: "short",
                })}
            </p>
          </div>
        </DateContainer>
        <h5>
          {type === "qr" && record.title} {type === "local" && record.title}
        </h5>
        <p>
          {/*type === "qr" && record.blog.content*/}{" "}
          {type === "local" && record.description}
        </p>
        <Link
          state={record}
          arabic={arabic}
          to={
            type === "qr"
              ? "/news-detail/" + record.id
              : "/news-detail/" + record.id
          }
        >
          {arabic === true ? "اقرأ أكثر" : "READ MORE"}
        </Link>
      </ArticleContainer>
    </div>
  )
}

export default NewsItemBlog
