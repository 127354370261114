import styled from "styled-components"

export const WorksContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 90px 0;
  align-items: center;
  text-align: center;

  h2 {
    font-size: 2.5rem;
    font-weight: 900;
    color: black;
  }

  .work-icons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    margin-top: 50px;

    a {
      text-decoration: none;
      max-width: 300px;
    }

    @media (max-width: 992px) {
      flex-direction: column;
    }
  }
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 45px;
  width: 100%;
  text-align: center;

  h4 {
    font-size: 1.66rem;
    font-weight: 400;
  }

  h4,
  p {
    text-decoration: none;
    color: black;
  }

  &:hover {
    svg {
      fill: #3b3c41;
    }
    img {
      opacity:0.5
    }
  }
`
