import styled from "styled-components"

export const EventsContainer = styled.div`
  padding: 90px 0;
`

export const UpperText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    font-weight: bold;
    color: var(--darkText);
  }

  p {
    width: 50%;
  }
`

export const EventContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  article {
    width: 50%;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 30px;

    article {
      width: 100%;
    }
  }
`
