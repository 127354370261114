import React, { useEffect, useState } from "react"

import { WorksContent, IconContainer } from "./HowItWorks.styles"
import { Link } from "gatsby"
import useWidget from "../../hooks/useWidget"
import Loader from "../../components/Loader"

function HowItWorks(props) {
  const widgetData = useWidget("how-we-works")[0]
  const [loading, setLoading] = useState(true)

  const arabic = props.arabic
  useEffect(() => {
    setLoading(false)
  }, [widgetData])

  if (loading) {
    return <Loader />
  } else {
    var i = 0
    return (
      <div className="container">
        <WorksContent>
          <h2>
            {widgetData && arabic == false
              ? widgetData.title
              : widgetData.title_ar}
          </h2>
          <p>
            {widgetData && arabic == false
              ? widgetData.description
              : widgetData.description_ar}
          </p>
          <div className="work-icons">
            {widgetData &&
              widgetData.details &&
              widgetData.details.map((record, key) => {
                i += 1
                return (
                  <Link key={record.title} to={record.link}>
                    <IconContainer>
                      <img
                        src={`data:image/svg+xml;base64,${btoa(
                          unescape(encodeURIComponent(record.svg))
                        )}`}
                      />
                      <h4>
                        {arabic == false ? record.title : record.title_ar}
                      </h4>
                      <p>
                        {key == 0 && (
                          <Link to="/premium">
                            {arabic == false
                              ? "Premium"
                              : "المستخدمون المتميزون"}
                          </Link>
                        )}
                        {arabic == false
                          ? record.description
                          : record.description_ar}
                      </p>
                    </IconContainer>
                  </Link>
                )
              })}
          </div>
        </WorksContent>
      </div>
    )
  }
}

export default HowItWorks
