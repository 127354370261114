import React, { useState, useEffect } from "react"

// import Swiper core and required modules
import { Pagination, Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import { db } from "../../firebase/config"
import { collection, getDocs, doc, updateDoc, setDoc } from "firebase/firestore"
import country from "../../assets/countriesStates.json"
// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"

import { HeroContainer, SearchModule, BottomControls } from "./HomeHero.styles"

import HomeHeroItem from "./HomeHeroItem"
import { Link } from "gatsby"

const HomeHero = ({ name, data, arabic }) => {
  const tabel = country.map(type => {
    return { value: type.name, label: type.name }
  })

  const [propertyTypes, setPropertyTypes] = useState([])
  const [countries, setCountries] = useState([])
  // search values
  const [searchValue, setSearchValue] = useState("")

  const [state, setState] = useState({})
  const [visitors, setVisitors] = useState("")
  useEffect(() => {
    getDocs(collection(db, "property_type")).then(documents => {
      const data = []
      if (documents) {
        documents.forEach(type => {
          data.push({ id: type.id, data: type.data() })
        })
        setPropertyTypes(data)
      }
    })

    getDocs(collection(db, "countries")).then(documents => {
      let results = []
      if (documents) {
        documents.forEach(document => {
          results.push({ id: document.id, data: document.data() })
        })
        setCountries(results)
      }
    })

    getDocs(collection(db, "visitorscounts")).then(documents => {
      let visitor = Intl.DateTimeFormat().resolvedOptions().timeZone
      visitor = visitor.split("/")[1]
      let increment = 1

      if (documents) {
        let thevisitors = ""
        documents.forEach(document => {
          if (visitor === document.id) {
            increment = document.data().view + 1
            console.log("document is ", increment)
          }
          thevisitors += document.id + " : " + document.data().view + " ---- "
        })
        setVisitors(thevisitors)
      }
      if (increment < 2) {
        const docRef = doc(db, "visitorscounts", visitor)
        updateDoc(docRef, { view: increment })
        console.log("updated is ", increment)
      } else {
        const docRef = doc(db, "visitorscounts", visitor)
        setDoc(docRef, { view: increment })
        console.log("adding is ", increment)
      }
    })
  }, [])

  // change type handler
  const changeType = e => {
    const index = e.nativeEvent.target.selectedIndex
    const value = e.target.value
    const label = e.nativeEvent.target[index].text
    let newState = state
    newState.category = {
      value,
      label,
    }
    setState(newState)
  }

  // change the location handler
  const changeLocation = e => {
    const index = e.nativeEvent.target.selectedIndex
    const value = e.target.value
    const label = e.nativeEvent.target[index].text
    let newState = state
    newState.country = {
      value,
      label,
    }
    setState(newState)
  }

  // handling the text change
  const handleText = e => {
    setSearchValue(e.target.value)
    let newState = state
    newState.text = {
      value: e.target.value,
    }
    setState(newState)
  }

  return (
    <>
      <HeroContainer>
        <Swiper
          slidesPerView={1}
          modules={[Pagination, Navigation]}
          pagination={{
            el: ".pagination",
            clickable: true,
          }}
          navigation={{
            nextEl: ".next-btn",
            prevEl: ".prev-btn",
          }}
        >
          {data.map(section => {
            return (
              <SwiperSlide key={section.node.id}>
                <HomeHeroItem data={section.node} arabic={arabic} />
              </SwiperSlide>
            )
          })}
        </Swiper>
        <SearchModule>
          <div className="search-wrapper">
            <div className="property-type">
              <label
                htmlFor="type"
                className={arabic === false ? "" : "text-end"}
              >
                {arabic === false ? "PROPERTY TYPE" : "نوع الملكية"}
              </label>
              <select
                name="type"
                defaultValue={0}
                id="type"
                onChange={changeType}
              >
                <option value="0" disabled>
                  {arabic === false ? "Choose Type" : "اختر نوع الملكية"}
                </option>
                {propertyTypes.length !== 0 &&
                  propertyTypes.map(type => {
                    return (
                      <option key={type.id} value={type.id}>
                        {arabic === false ? type.data.title : type.data.title_ar}
                      </option>
                    )
                  })}
              </select>
            </div>
            <div className="location">
              <label
                htmlFor="location"
                className={arabic === false ? "" : "text-end"}
              >
                {arabic === false ? "LOCATION" : "موقعك"}
              </label>
              <div className="search-bar">
                <select
                  name="type"
                  onChange={changeLocation}
                  defaultValue={0}
                  id="type"
                >
                  <option value="0" disabled>
                    {arabic === false ? "Where to look" : "أين تبحث"}
                  </option>
                  {country.length !== 0 &&
                    country.map(country => {
                      return (
                        <option key={country.name} value={country.name}>
                          {country.name}
                        </option>
                      )
                    })}
                </select>
              </div>
            </div>
            <div className="search">
              <label
                htmlFor="search"
                className={arabic === false ? "" : "text-end"}
              >
                {arabic === false ? "SEARCH" : "بحث"}
              </label>
              <input
                type="text"
                name="search"
                id="search"
                value={searchValue}
                onChange={handleText}
                placeholder={
                  arabic === false ? "Enter Search Term" : "أدخل مصطلح البحث"
                }
              />
            </div>
            <div className="submit">
              <Link type="submit" to="/find-a-property" state={state}>
                <svg
                  id="Layer_2"
                  data-name="Layer 2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <g id="search">
                    <rect
                      id="Rectangle_31"
                      data-name="Rectangle 31"
                      width="24"
                      height="24"
                      fill="#477b11"
                      opacity="0"
                    />
                    <path
                      id="Path_6"
                      data-name="Path 6"
                      d="M20.71,19.29l-3.4-3.39A7.92,7.92,0,0,0,19,11a8,8,0,1,0-8,8,7.92,7.92,0,0,0,4.9-1.69l3.39,3.4a1,1,0,1,0,1.42-1.42ZM5,11a6,6,0,1,1,6,6,6,6,0,0,1-6-6Z"
                      fill="#477b11"
                    />
                  </g>
                </svg>
              </Link>
            </div>
          </div>
        </SearchModule>
      </HeroContainer>
      <BottomControls>
        <div className="container">
          <marquee style={{ color: "white" }}>
            Number of site visit by region: {visitors}
            (under enhancment)
          </marquee>
          <div className="controls-container">
            <div className="controls">
              <div className="navigation">
                <div className="prev-btn nav-btn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22.013"
                    height="19.991"
                    viewBox="0 0 22.013 19.991"
                  >
                    <g
                      id="Group_5"
                      data-name="Group 5"
                      transform="translate(1.405 0.707)"
                    >
                      <path
                        id="Path_4"
                        data-name="Path 4"
                        d="M-1615.862,765.8h-20.228"
                        transform="translate(1636.47 -756.611)"
                        fill="none"
                        stroke="#477b11"
                        strokeWidth="2"
                      />
                      <path
                        id="Path_5"
                        data-name="Path 5"
                        d="M-1616.481,775.2l-9.17-9.424,9.17-9.162"
                        transform="translate(1625.652 -756.611)"
                        fill="none"
                        stroke="#477b11"
                        strokeWidth="2"
                      />
                    </g>
                  </svg>
                </div>
                <div className="next-btn nav-btn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22.013"
                    height="19.991"
                    viewBox="0 0 22.013 19.991"
                  >
                    <g
                      id="Group_5"
                      data-name="Group 5"
                      transform="translate(1637.09 -755.904)"
                    >
                      <path
                        id="Path_4"
                        data-name="Path 4"
                        d="M-1636.09,765.8h20.228"
                        transform="translate(-1)"
                        fill="none"
                        stroke="#477b11"
                        strokeWidth="2"
                      />
                      <path
                        id="Path_5"
                        data-name="Path 5"
                        d="M-1625.652,775.2l9.17-9.424-9.17-9.162"
                        fill="none"
                        stroke="#477b11"
                        strokeWidth="2"
                      />
                    </g>
                  </svg>
                </div>
              </div>
              <div className="pagination"></div>
            </div>
          </div>
        </div>
      </BottomControls>
    </>
  )
}

export default HomeHero
