import React, { useState, useEffect } from "react"

import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import {
  PropertyContainer,
  TextContainer,
  Types,
  TypeContainer,
  ViewAll,
} from "./HomePropertyType.styles"
import Img from "gatsby-image"

//icons
import commercial from "../../assets/icons/commercial-icon.svg"
import residential from "../../assets/icons/residential-icon.svg"
import vacant from "../../assets/icons/vacant-land-icon.svg"
import { useCollection } from "../../hooks/useCollection"
import useWidget from "../../hooks/useWidget"
import Loader from "../../components/Loader"

function HomePropertyType(props) {
  const [propertyTypes, setPropertyTypes] = useState([])

  useCollection("property_type").then(types => {
    if (types) {
      setPropertyTypes(types)
    }
  })
  const arabic = props.arabic
  const widgetData = useWidget("property-type")[0]
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(false)
  }, [widgetData])

  return (
    <PropertyContainer className="container-fluid">
      <div className="row p-0 m-0">
        <div className="col-lg-5">
          <Img
            fixed={props.data.localImage?.childImageSharp.fixed}
            alt="modern hallway"
            placeholder="tracedSVG"
            className="hallway-img"
            layout="fullWidth"
          />
        </div>
        <div className="col-lg-7">
          <TextContainer>
            <div className="text-centered">
              <h2>
                {widgetData && arabic == false
                  ? widgetData.title
                  : widgetData.title_ar}
              </h2>
              <p className={arabic == false ? "minimized" : "w-0 minimized"}>
                {widgetData && arabic == false
                  ? widgetData.description
                  : widgetData.description_ar}
              </p>
            </div>
            <Types>
              {widgetData &&
                widgetData.details &&
                widgetData.details.map(record => {
                  return (
                    <Link
                      to="/find-a-property"
                      state={{
                        category: {
                          value: record.title,
                          label: record.title,
                        },
                      }}
                      key={arabic == false ? record.title : record.title_ar}
                    >
                      <TypeContainer>
                        <img src={record.svg} alt={`${record.title}`} />
                        <h6>
                          {arabic == false ? record.title : record.title_ar}
                        </h6>
                      </TypeContainer>
                    </Link>
                  )
                })}
            </Types>
            <ViewAll>
              <Link to="/property-type">
                <span>
                  {arabic == false ? "View All Types" : "عرض كل الأنواع"}
                </span>
                <span className="arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8.414"
                    height="15.067"
                    viewBox="0 0 8.414 15.067"
                  >
                    <path
                      id="arrow"
                      d="M12.239,0,6.119,6,0,0"
                      transform="translate(1.414 13.653) rotate(-90)"
                      fill="none"
                      stroke="#477b11"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                      strokeWidth="2"
                    />
                  </svg>
                </span>
              </Link>
            </ViewAll>
          </TextContainer>
        </div>
      </div>
    </PropertyContainer>
  )
}

export default HomePropertyType
