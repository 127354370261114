import React, { useState, useEffect } from "react"

import { Link, graphql } from "gatsby"

// components
import HowItWorks from "../components/HowItWorks"
import HomePropertyType from "../components/HomePropertyType"
import ListingsCarousel from "../components/ListingsCarousel"
import HomeHero from "../components/HomeHero"
import InternalAds from "../components/InternalAds"
import LatestEvents from "../components/LatestEvents"
import usePage from "../hooks/usePage"
import PropertType from "../components/PropertyType"
import LatestNewsBlog from "../components/LatestNewsBlog"
import PricingTables from "../components/PricingTables"

const Widgets = ({ widgetsData, data, arabic }) => {
  const [pageWidgets, setPageWidgets] = data.allPage.edges

  useEffect(() => {
    //setAllRows(navData);
  }, [pageWidgets])
  return (
    <>
      {pageWidgets.node &&
        pageWidgets.node.widgets &&
        pageWidgets.node.widgets.map(record => {
          switch (record.type) {
            case "hero":
              return (
                <HomeHero
                  name={record.name}
                  data={data.allHero.edges}
                  arabic={arabic}
                />
              )
              break
            case "how-we-works":
             /*return (
                <HowItWorks
                  name={record.name}
                  data={data.widget1}
                  arabic={arabic}
              />);*/
              return(
                <PricingTables 
                name={record.name}
                  data={data.widget1}
                  arabic={arabic}
                />
                )
              break
            case "property-type":
              return (
                <HomePropertyType
                  name={record.name}
                  data={data.widget2}
                  arabic={arabic}
                />
              )
              break
            case "explore-properties":
              return (
                <ListingsCarousel
                  name={record.name}
                  data={data.widget3}
                  arabic={arabic}
                />
              )
              break
            case "our-latest-news":
              return (
                <LatestNewsBlog
                  name={record.name}
                  data={data.widget4}
                  news={data.allNews}
                  arabic={arabic}
                />
              )
              break
            case "call-to-action":
              return (
                <InternalAds
                  name={record.name}
                  data={data.widget5}
                  arabic={arabic}
                />
              )
              break
            case "latest-events":
              if (data.allEvents?.length > 0) {
                return (
                  <LatestEvents
                    name={record.name}
                    data={data.widget6}
                    events={data.allEvents}
                    arabic={arabic}
                  />
                )
              } else {
                return <></>
              }
              break
            case "type-property":
              return <PropertType name={record.name} />
            default:
          }
        })}
    </>
  )
}

export default Widgets
