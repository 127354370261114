import React, { useState, useEffect } from "react"
import useWidget from "../../hooks/useWidget"
import { Container } from "./PricingTables.Styles";
import Loader from "../Loader";
function PricingTables(props) {
    const widgetData = useWidget("how-we-works")[0];
    const [loading, setLoading] = useState(true)
    const arabic = props.arabic;
    const direction = arabic ? 'rtl' : 'ltr';

    useEffect(() => {
      setLoading(false)
    }, [widgetData])
  
    if (loading) {
      return <Loader />
    } else {
    return(
 <Container style={{'direction':direction}}>
    <h2>{widgetData && arabic == false
              ? widgetData.title
              : widgetData.title_ar}</h2>
    <p>{arabic ? 'فقط إعلان عقاري مدفوع ومعفى من الوساطة العقارية' : 'Only Premium & Brokerage Exempt'}</p>
        <h3>{arabic ? 'التسعير الشهري' : 'MONTHLY DOT VIP PRICING'}</h3>
<p>{arabic ? 'قائمة العقارات' : 'REAL ESTATE LISTING'}</p>
<div className="pricing-tables">
<div className="columns" key={1}>
  <ul className="price">
    <li className="header">{arabic ? 'أساسي' : 'Basic'}</li>
    <li className="grey">{arabic ? '100 $' : '100 $'}</li>
    <li>{arabic ? 'فيسبوك' : 'Facebook'}</li>
    <li>{arabic ? 'انستغرام' : 'Instagram'}</li>
    <li><del>{arabic ? 'إعلانات جوجل' : 'Google ADS'}</del></li>
    <li><del>{arabic ? 'الصفحة المقصودة' : 'Landing Page'}</del></li>
    <li className="grey"><a target="_blank" href={arabic ? 'https://new.propertypro.vip/ar/plans/Basic' : 'https://new.propertypro.vip/plans/Basic' } className="button">{arabic ? 'اختر خطة' : 'Select Plan' }</a></li>
  </ul>
</div>

<div className="columns" key={2}>
  <ul className="price">
    <li className="header darkgreen">{arabic ? 'ابتدائي' : 'Standard'}</li>
    <li className="grey">{arabic ? '500 $' : '500 $'}</li>
    <li>{arabic ? 'فيسبوك' : 'Facebook'}</li>
    <li>{arabic ? 'انستغرام' : 'Instagram'}</li>
    <li>{arabic ? 'إعلانات جوجل' : 'Google ADS'}</li>
    <li><del>{arabic ? 'الصفحة المقصودة' : 'Landing Page'}</del></li>
    <li className="grey"><a  target="_blank" href={arabic ? 'https://new.propertypro.vip/ar/plans/Standard' : 'https://new.propertypro.vip/plans/Standard' } className="button">{arabic ? 'اختر خطة' : 'Select Plan' }</a></li>
  </ul>
</div>

<div className="columns" key={3}>
  <ul className="price">
    <li className="header darkblue">{arabic ? 'الممتازة' : 'Premium'}</li>
    <li className="grey">{arabic ? '1000 $' : '1000 $'}</li>
    <li>{arabic ? 'فيسبوك' : 'Facebook'}</li>
    <li>{arabic ? 'انستغرام' : 'Instagram'}</li>
    <li>{arabic ? 'إعلانات جوجل' : 'Google ADS'}</li>
    <li>{arabic ? 'الصفحة المقصودة' : 'Landing Page'}</li>
    <li className="grey"><a target="_blank" href={arabic ? 'https://new.propertypro.vip/ar/plans/Premium' : 'https://new.propertypro.vip/plans/Premium' } className="button">{arabic ? 'اختر خطة' : 'Select Plan' }</a></li>
  </ul>
</div>
</div><br/>
<p>{arabic ? 'قد تكون جميع القوائم قابلة للاستبدال عند بيعها أو تأجيرها' : 'All listing may be replaceable when sold or rented'}</p>
<br/>
</Container>
    )}
}
export default PricingTables;