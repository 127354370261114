import React, { useState, useEffect } from "react"

import {
  NewsContainer,
  UpperText,
  ArticlesContainer,
} from "./LatestNews.styles"

import NewsItemBlog from "../GlobalComponents/NewsItemBlog"
import ExploreMore from "../GlobalComponents/ExploreMore"

import useWidget from "../../hooks/useWidget"
import useLatestNews from "../../hooks/useLatestNews"

import Loader from "../../components/Loader"

const LatestNewsBlog = props => {
  const latestNewsData = props.news.edges
  const arabic = props.arabic
  const [loading, setLoading] = useState(true)
  const widgetData = useWidget("our-latest-news")[0]
  const [blog, setBlog] = useState()
  // const data = useUserEmail(user.uid)
  useEffect(() => {
    console.log("loading")
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    }
    fetch(
      "https://www.googleapis.com/blogger/v3/blogs/5833795409785926601/posts?key=AIzaSyBdZwEnmytFEWrcd2WrNODWWe8nU76OepQ",
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        console.log("blog as result", blog)
        setBlog(result)
      })
      .catch(error => console.log("error", error))
    setLoading(false)
  }, [])

  useEffect(() => {
    console.log("blog", blog)
  }, [blog])

  return (
    <NewsContainer>
      <div className="container">
        <UpperText className={arabic == false ? "" : "flex-row-reverse w-0"}>
          <div className={arabic == false ? "text" : "text text-end"}>
            <h2>
              {widgetData && arabic == false
                ? widgetData.title
                : widgetData.title_ar}
            </h2>
            <p className={arabic == false ? "" : "w-100"}>
              {widgetData && arabic == false
                ? widgetData.description
                : widgetData.description_ar}
            </p>
          </div>
          <ExploreMore
            link="/news"
            text={
              widgetData && arabic == false
                ? widgetData.link_text
                : widgetData.link_text_ar
            }
          />
        </UpperText>
        <div className="row">
          {blog &&
            blog?.items.slice(0, 3).map(record => {
              return (
                <NewsItemBlog
                  className="col-6"
                  arabic={arabic}
                  type="qr"
                  record={record}
                />
              )
              /*return {
                /*<NewsItemBlog
                  className="col-6"
                  arabic={arabic}
                  type="qr"
                  record={record}
              />
              }*/
            })}
        </div>
      </div>
    </NewsContainer>
  )
}

export default LatestNewsBlog
