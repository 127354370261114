import styled from "styled-components"

export const PropertyContainer = styled.div`
  display: flex;
  justify-content: center;

  .container {
    position: relative;
    padding: 50px 0;
    margin-bottom: 50px;
    margin-top: 50px;
    @media only screen and (max-width: 600px) {
      .content {
        padding: 0 50px;
        text-align:center;
        a {
          
          z-index=1;
          font-size: 0.77rem;
          background-color: var(--lightGreen);
          padding: 1rem 2rem;
          text-align: center;
          color: white;
          border-radius: 5px;
          text-decoration: none;
          top: -200px;
        }
      }
  
      .villa-img {
        visibility:hidden;
        position: relative !important;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 10px;
        max-width:100%;
        @media (max-width: 576px) {
          border-radius: none;
        }
      }
      h2,
      p {
       
        z-index:1;
        width: 100%;
        margin-bottom: 25px;
        color: green;
        top: -200px;
        text-align:center;
      }
    }
  }
    .content {
      padding: 0 50px;

      a {
        position:relative;
        z-index=1
        font-size: 0.77rem;
        background-color: var(--lightGreen);
        padding: 1rem 2rem;
        text-align: center;
        color: white;
        border-radius: 5px;
        text-decoration: none;
        top: -200px;
      }
    }

    .villa-img {
      position: relative !important;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 10px;
      max-width:100%;
      @media (max-width: 576px) {
        border-radius: none;
      }
    }
    h2,
    p {
      position:relative;
      z-index:1;
      width: 100%;
      margin-bottom: 25px;
      color: white;
      top: -200px;
    }
  }
`
