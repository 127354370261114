import React, { useEffect, useState } from "react"

// Styled components
import { SwiperContainer, UpperText } from "./ListingsCarousel.styles"

// import Swiper core and required modules
import { Pagination, Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"

// components
import ListingsCarouselItem from "../ListingsCarouselItem"
import ExploreMore from "../GlobalComponents/ExploreMore"

//Icons
import Prev from "../../assets/icons/prev.svg"
import Next from "../../assets/icons/next.svg"

import { useAuthContext } from "../../hooks/useAuthContext"
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  orderBy,
} from "firebase/firestore"
import { db } from "../../firebase/config"

const ListingsCarousel = props => {
  const [liked_properties, setLikedProperties] = useState([])
  // getting the liked properties
  let { user } = useAuthContext()

  const arabic = props.arabic
  const [listings, setListings] = useState([])
  useEffect(() => {
    if (user) {
      getDoc(doc(db, "users", user.uid)).then(document => {
        if (document.data()) {
          setLikedProperties(document.data().liked_properties)
        }
      })
    }
  }, [])
  useEffect(() => {
    const fetchNav = async () => {
      const q = query(collection(db, "property"), orderBy("date", "desc"))
      const querySnapshot = await getDocs(q)
      let arr = []
      querySnapshot.forEach(doc => {
        arr.push({ id: doc.id, data: doc.data() })
      })
      setListings(arr)
    }

    fetchNav()
  }, [])
  const location = window.navigator && window.navigator.geolocation
  return (
    <SwiperContainer>
      <div className="container">
        <UpperText className={arabic === false ? "" : "flex-row-reverse"}>
          <div className={arabic === false ? "text-up" : "text-up text-end"}>
            <h2>{arabic === false ? props.data.title : props.data.title_ar}</h2>
            <p>
              {arabic === false
                ? props.data.description
                : props.data.description_ar}
            </p>
          </div>
          <ExploreMore
            link={props.data.link}
            text={
              arabic === false ? props.data.link_text : props.data.link_text_ar
            }
          />
        </UpperText>

        <Swiper
          cssMode={true}
          slidesPerView={1}
          spaceBetween={15}
          pagination={{
            type: "progressbar",
            el: ".swiper-pagination",
          }}
          breakpoints={{
            992: {
              slidesPerView: 3,
            },
          }}
          navigation={{
            nextEl: ".next-btn",
            prevEl: ".prev-btn",
          }}
          modules={[Pagination, Navigation]}
        >
          {listings.length !== 0 &&
            listings.map(listing => {
              if (!listing.data.isDraft) {
                const isLiked = liked_properties.includes(listing.id)
                return (
                  <SwiperSlide key={listing.id}>
                    <ListingsCarouselItem
                      listing={listing}
                      location={location}
                      isLiked={isLiked}
                      user={user}
                    />
                  </SwiperSlide>
                )
              }
            })}
        </Swiper>
        <div className="swiper-pagination"></div>
        <div className="swiper-navigation">
          <div className="prev-btn nav-btn">
            <img src={Prev} alt="previous button" />
          </div>
          <div className="next-btn nav-btn">
            <img src={Next} alt="next button" />
          </div>
        </div>
      </div>
    </SwiperContainer>
  )
}

export default ListingsCarousel
