import styled from "styled-components"

export const HeroContainer = styled.div`
  color: white;
  position: relative;
  z-index: 1;
`

export const Slide = styled.div`
  min-height: 500px;
  position: relative;
  display: flex;
  align-items: center;
  .hallway-img.gatsby-image-wrapper {
    width: 1800px !important;
  }
  @media (max-width: 992px) {
    flex-direction: column;
    min-height: unset;
    padding: 20px 0;
  }

  .background {
    position: absolute;
    left: 0;
    right: 0;
    height: 90%;
    background-color: var(--darkBlue);
    bottom: 0;
    z-index: -1;

    @media (max-width: 992px) {
      height: 100%;
    }
  }

  .text {
    width: 40%;
    margin-left: -90px;

    @media (max-width: 992px) {
      width: 100%;
      padding: 30px 0;
      margin-left: 0px;
    }

    h5 {
      font-size: 0.77rem;
    }

    h2 {
      font-size: 3.33rem;
      font-weight: bold;
    }

    p {
      margin-bottom: 2rem;
      opacity: 70%;
    }

    a {
      background-color: var(--lightGreen);
      padding: 1rem 3.3rem;
      font-size: 0.77rem;
      color: white;
      text-align: center;
      text-decoration: none;
      border-radius: 5px;
    }
  }

  img {
    height: 500px;
    width: 60%;
    object-fit: cover;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 14px 0 0 0;

    @media (max-width: 992px) {
      position: static;
      height: unset;
      width: 90%;
      border-radius: 14px;
    }

    @media (max-width: 576px) {
      width: 95%;
    }
  }
`

export const SearchModule = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 25px 0;
  width: 70%;
  background-color: white;
  z-index: 1;
  border-radius: 0 10px 0 0;

  .search-wrapper {
    display: flex;
    justify-content: center;
    align-items: stretch;
    border: 1px solid #dddddd;
    width: 95%;
    margin: 0 auto;
    border-radius: 14px;

    @media (max-width: 576px) {
      flex-direction: column;
    }
  }

  @media (max-width: 992px) {
    position: static;
    width: 100%;
    border-radius: none;
    flex-direction: column;
  }

  label {
    color: #1d8cc3;
    font-size: 0.66rem;
    font-weight: bold;
  }

  input:focus,
  select:focus {
    outline: none;
  }

  .property-type,
  .location {
    width: 25%;

    select {
      color: #6e6e6e;
    }

    option {
      color: black !important;
    }

    @media (max-width: 576px) {
      width: 100%;
    }
  }
  .search {
    width: 43%;

    @media (max-width: 576px) {
      width: 100%;
    }
  }
  .submit {
    width: 7%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 576px) {
      width: 100%;
    }

    button {
      border: none;
      background: none;
    }
  }

  .property-type,
  .location,
  .search {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 2px 5px;
    border-right: 1px solid #dddddd;

    @media (max-width: 576px) {
      border-right: unset;
      border-bottom: 1px solid #dddddd;
    }

    .search-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    select,
    input {
      width: 100%;
      border: none;

      option {
        color: #b4b4b4;
      }
    }
  }
`

export const BottomControls = styled.div`
  background-color: var(--darkBlue);
  padding: 20px 0 30px;
  .controls-container {
    width: 70%;

    .controls {
      /* display: flex; */
      justify-content: space-between;
      align-items: center;
      display: none;

      .navigation {
        display: flex;
        align-items: center;
        gap: 10px;

        .nav-btn {
          background-color: #e6e6e8;
          border-radius: 50%;
          padding: 10px;
          cursor: pointer;
        }
      }

      .pagination {
        width: fit-content;
        display: flex;
        align-items: flex-end;
        gap: 2px;

        .swiper-pagination-bullet.swiper-pagination-bullet-active {
          width: 20px;
          height: 5px;
          border-radius: unset;
          background-color: var(--lightBlue);
        }

        .swiper-pagination-bullet {
          width: 20px;
          height: 2px;
          border-radius: unset;
          background-color: #9b9b9b;
        }
      }
    }
  }
`
