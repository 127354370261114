import React from "react"

import { Slide } from "./HomeHero.styles"

import { Link } from "gatsby"


function HomeHeroItem({ data, arabic }) {
  console.log("data", data)
  return (
    <Slide>
      <div className="container">
        <div className={arabic ? "text-end text" : "text"}>
          <h5>{arabic === false ? data.tagline : data.tagline_ar}</h5>
          <h2>{arabic === false ? data.title : data.title_ar}</h2>
          <p>{arabic === false ? data.paragraph : data.paragraph_ar}</p>
          {data.link.text && data.link.url && (
            <Link to={data.link.url}>
              {arabic === false ? data.link.text : data.link.text_ar}
            </Link>
          )}
        </div>
      </div>
      <img
        src={data.image.url}
        alt="Home Hero"
        className="hallway-img"
        // layout="fullWidth"
      />

      <div className="background"></div>
    </Slide>
  )
}

export default HomeHeroItem
