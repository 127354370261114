import styled from "styled-components"

export const ItemContainer = styled.div`
direction:rtl;
  h4 {
    
    font-weight: bold;
    margin-top: 10px;
    font-size: 1.5rem;
    height: 57px;
  }
  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }
`

export const ImageContainer = styled.div`
  position: relative;

  .image {
    width: 100%;
    height: 217px;
    object-fit: cover;
    border-radius: 10px;
    img {
      &:hover {
        transform: scale(1.1);
        transition: 0.1s ease-in;
      }
    }
  }
`

export const LocationText = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  p {
    margin: 0;
    font-size: 0.83rem;
    color: #7e7e7e;
  }
`

export const UpperImageTools = styled.div`
  position: absolute;
  top: 10px;
  width: 100%;
  padding: 0 10px;
  display: flex;
  justify-content: ${({ locationExists }) =>
    locationExists ? "space-between" : "flex-end"};
  align-items: center;
`

export const FarAway = styled.div`
  background-color: rgba(112, 112, 112, 40%);
  color: white;
  font-weight: bold;
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 13px;
  font-size: 0.88rem;
`

export const Like = styled.button`
  background-color: rgba(71, 123, 17, 0.5);
  padding: 10px;
  border-radius: 50%;
  border: none;

  svg {
    path {
      fill: ${({ isLiked }) => (isLiked ? "white" : "transparent")};
    }
  }
  svg {
    &:hover {
      path {
        fill: white;
      }
    }
  }
`

export const LocationIcon = styled.div`
direction:rtl;
  background-color: #eeeff1;
  padding: 10px;
  position: absolute;
  bottom: -15px;
  right: 10px;
  border-radius: 50%;
  box-shadow: 0 10px 20px rgba(30, 98, 232, 0.24);
`

export const BottomContainer = styled.div`
direction:rtl;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 10px;
`

export const FeaturesContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  padding: 14px 10px;
  background-color: #d0dbc5;
  border-radius: 10px;
  color: var(--darkGreen);
  font-weight: bold;
  font-size: 0.88rem;
  flex-shrink: 0;
`

export const PriceContainer = styled.div`
  color: var(--darkGreen);
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1;
  max-height: 49px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.8rem;
  margin-left: 2%;
`
