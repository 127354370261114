import React, {useState, useEffect} from "react"
import Loader from "../Loader"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Img from 'gatsby-image';

import { PropertyContainer } from "./InternalAds.styles"
import useWidget from "../../hooks/useWidget"
const InternalAds = (props) => {
  // const widgetData = props.data
  const [loading,setLoading] = useState(true);
  const arabic=props.arabic;
  useEffect(() => {
    setLoading(false);
  },[widgetData])
  const widgetData =useWidget('it-is-time-to-expect-more')[0];

if(loading) {
  return (<Loader />)
}
else {
  return (
    <PropertyContainer>
      <div className="container">
       {/* <Img
            fixed={widgetData.localImage.childImageSharp.fixed}
            alt="internal add img"
            className="villa-img"
          />   */}
        <img src={widgetData.image} className="villa-img" alt="internal add img"></img>
        <div className={arabic==false ? "content":"content text-end"}>
          <h2>{widgetData && arabic==false? widgetData.title:widgetData.title_ar}</h2>
          <p>
            {widgetData && arabic == false ? widgetData.description:widgetData.description_ar}
          </p>
          <Link to={widgetData && widgetData.link}>{widgetData && arabic==false ? widgetData.link_text : widgetData.link_text_ar}</Link>
        </div>
      </div>
    </PropertyContainer>
  )
}
}

export default InternalAds
